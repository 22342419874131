import {
    ADDRESS_FIELDS_FRAGMENT,
    COUNTRY_FIELDS_FRAGMENT,
    DISCOUNT_FIELDS_FRAGMENT,
    DISCOUNT_COUPON_FIELDS_FRAGMENT,
    ORDER_FIELDS_FRAGMENT,
    PAYMENT_GATEWAY_FIELDS_FRAGMENT,
    COURIER_FIELDS_FRAGMENT,
    TRACKING_ORDER_FIELDS_FRAGMENT
} from "./fragments";

export const GET_ORDER = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    query GetOrder($data: GetOrderInput!) {
        getOrder(data: $data) {
            ...OrderFields
        }
    }
`;

export const GET_AND_VALIDATE_ORDER = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    query GetAndValidateOrder($data: GetOrderInput!) {
        getAndValidateOrder(data: $data) {
            ...OrderFields
        }
    }
`;
export const AIRWALLEX_CHECK_PAYMENT_INTENT = /* GraphQL */ `
    query AirwallexCheckPaymentIntent($data: AirwallexCheckPaymentIntentInput!) {
        airwallexCheckPaymentIntent(data: $data)
    }
`;
export const GET_CART_SUGGESTED_PRODUCTS = /* GraphQL */ `
    mutation GetCartSuggestedProducts($data: GetCartSuggestedProductsInput!) {
        getCartSuggestedProducts(data: $data) {
            product
            addedProduct
            SKUName
            SKUCode
            imageUrl
            currentPrice
        }
    }
`;
export const GET_CUSTOMER = /* GraphQL */ `
    query GetCustomer($id: ID!) {
        getCustomer(id: $id) {
            id
            firstName
            lastName
            email
            phoneNumber
        }
    }
`;

export const GET_ADDRESSES_BY_POST_CODE = `
  ${ADDRESS_FIELDS_FRAGMENT}
  query GetAddressesByPostCode($postCode: String, $salesChannel: String,$tenant:String) {
      getAddressesByPostCode(postCode: $postCode, salesChannel: $salesChannel,tenant:$tenant) {
        ...AddressFields
      }
  }
`;

export const GET_LOGGED_IN_CUSTOMER = /* GraphQL */ `
    query getLoggedInCustomerData {
        getLoggedInCustomerData {
            id
            firstName
            lastName
            email
            phoneNumber
            creditAmountTotal
            referralCode
            addresses {
                id
            }
            reviews {
                id
            }
            credits {
                id
                date
                amount
                description
            }
        }
    }
`;

export const GET_CUSTOMER_BY_EMAIL = /* GraphQL */ `
    query GetCustomerByEmail($salesChannel: String!, $email: String!, $tenant: String!) {
        getCustomerByEmailSalesChannel(
            email: $email
            salesChannel: $salesChannel
            tenant: $tenant
        ) {
            data {
                id
                firstName
                lastName
                email
                phoneNumber
                cognitoUser
                salesChannel
            }
        }
    }
`;
export const SHIPPING_METHOD_FIELDS_FRAGMENT = /* GraphQL */ `
    fragment ShippingMethodFields on ShippingMethod {
        id
        type
        name
        displayName
        courier {
            id
            name
            type
        }
        config {
            propertyId
            property
            value
        }
        freeOver
        salesChannelFreeOver
        imageUrl
        minWeight
        maxWeight
        sort
        days
    }
`;

export const GET_SHIPPING_METHODS = /* GraphQL */ `
    ${SHIPPING_METHOD_FIELDS_FRAGMENT}
    query GetShippingMethods($tenant: String) {
        getShippingMethods(tenant: $tenant) {
            data {
                ...ShippingMethodFields
            }
        }
    }
`;

export const GET_SHIPPING_METHOD = /* GraphQL */ `
    ${SHIPPING_METHOD_FIELDS_FRAGMENT}
    query GetShippingMethod($id: String!, $tenant: String) {
        getShippingMethod(id: $id, tenant: $tenant) {
            ...ShippingMethodFields
        }
    }
`;

export const GET_PRODUCT = /* GraphQL */ `
    query GetProduct($id: String!, $tenant: String) {
        getProduct(id: $id, tenant: $tenant) {
            id
            SKUName
            SKUCode

            currentPrice
            listPrice

            imageUrl
            shortName
            fullDescription
            fullDescriptionBySalesChannel
            shortDescription
        }
    }
`;
export const GET_PRODUCT_DATA = /* GraphQL */ `
    query GetProductData($id: String!, $tenant: String, $salesChannel: String) {
        getProductData(id: $id, tenant: $tenant, salesChannel: $salesChannel) {
            currentPrice
            listPrice
            onHand
            stock {
                text
                type
                color
                fontColour
                presaleDate
                presaleLimit
            }
            reviewSummary {
                id
                min
                max
                rating
                count
                starCount
                reviewsUrl
            }
        }
    }
`;
export const GET_PRODUCT_DATES = /* GraphQL */ `
    mutation GetProductDates(
        $productId: String!
        $postcode: String!
        $tenant: String!
        $salesChannel: String!
    ) {
        getProductDates(
            productId: $productId
            postcode: $postcode
            tenant: $tenant
            salesChannel: $salesChannel
        ) {
            estDeliveryDate
            estDispatchDate
        }
    }
`;
export const GET_PRODUCT_TAX_CODES_DATA = /* GraphQL */ `
    query GetProductTaxCodesData($id: String!, $tenant: String) {
        getProductTaxCodesData(id: $id, tenant: $tenant) {
            id
            product
            taxCode
            name
            taxRate
            countryCodeRegex
            notCountryCodeRegex
            stateCodeRegex
            notStateCodeRegex
            zipCodeRegex
            notZipCodeRegex
        }
    }
`;

export const GET_CUSTOMER_ORDERS = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    query GetCustomerOrders($data: GetCustomerOrdersInput!) {
        getCustomerOrders(data: $data) {
            ...OrderFields
        }
    }
`;
export const GET_MY_ADDRESSES = /* GraphQL */ `
    ${ADDRESS_FIELDS_FRAGMENT}
    query GetMyAddresses {
        getMyAddresses {
            ...AddressFields
        }
    }
`;
export const GET_ORDERS = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    query GetOrders($data: GetOrdersInput!) {
        getOrders(data: $data) {
            ...OrderFields
        }
    }
`;

export const LIST_PAYMENT_GATEWAYS = /* GraphQL */ `
    ${PAYMENT_GATEWAY_FIELDS_FRAGMENT}
    query GetPaymentGateways($tenant_id: String) {
        getPaymentGatewaysForWebsite(tenant_id: $tenant_id) {
            data {
                ...PaymentGatewayFields
            }
        }
    }
`;
export const GET_PAYMENT_GATEWAY_BY_TYPE = `
    ${PAYMENT_GATEWAY_FIELDS_FRAGMENT}
    query GetPaymentGatewayByType($type: String) {
        paymentGateways {
            getPaymentGatewayByType(type: $type) {
                ...PaymentGatewayFields
            }
        }
    }
`;

export const GET_COUNTRIES = `
  ${COUNTRY_FIELDS_FRAGMENT}
  query GetCountries($tenant:String){
      getCountries(tenant:$tenant){
        data {
          ...CountryFields
        }
      }
    }

`;
export const GET_CURRENCY_CODES = `
  query GetCurrencyCodes($tenant:String){
      getCurrencyCodes(tenant:$tenant){
        data {
            id
            name
            currencyCode
            formatString
            roundTo
            isBase
        }
    }
  }
`;

export const GET_COURIER = `
  ${COURIER_FIELDS_FRAGMENT}
  query GetCourier($id: String!, $tenant:String) {
      getCourier(id: $id,tenant:$tenant) {
        ...CourierFields
      }
  }
`;

export const GET_ORDER_BY_NUMBER = `
${ORDER_FIELDS_FRAGMENT}
  query GetOrderByNumber($data: GetOrderByNumberInput!){
      getOrderByNumber(data:$data) {
           ...OrderFields
      }
  }
`;

export const GET_ORDER_BY_NUMBER_FOR_TRACKING = `
${TRACKING_ORDER_FIELDS_FRAGMENT}
  query GetOrderByNumber($data: GetOrderByNumberInput!){
      getOrderByNumber(data:$data) {
           ...OrderFields
      }
  }`;
export const GET_REVIEW_PLATFORMS = /* GraphQL */ `
    query GetReviewPlatforms($tenant_id: String, $salesChannel: String) {
        getReviewPlatforms(tenant_id: $tenant_id, salesChannel: $salesChannel) {
            data {
                id
                name
                enabled
                type
                config {
                    property
                    value
                }
                cb
                sb
                co
                so
            }
        }
    }
`;

export const EDIT_CUSTOMER_REFERRAL_CODE = /* GraphQL */ `
    mutation EditCustomerReferralCode($id: String!, $salesChannel: String!, $newCode: String!) {
        editCustomerReferralCode(id: $id, salesChannel: $salesChannel, newCode: $newCode) {
            success
            message
        }
    }
`;

export const GET_REVIEWS_BY_SKU = /* GraphQL */ `
    query GetReviewsBySKU($sku: String, $tenant: String) {
        getReviewsBySKU(SKUCode: $sku, tenant: $tenant) {
            id
            title
            author
            body
            ratingValue
            datePublished
            product
            externalRef
            url
            productId
        }
    }
`;
